import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
//import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import { Pagination, PaginationItem } from "@material-ui/lab";

//import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
//import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import CompareIcon from "@material-ui/icons/Compare";
import CloudIcon from "@material-ui/icons/Cloud";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import RefreshIcon from "@material-ui/icons/Refresh";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import LayersIcon from "@material-ui/icons/Layers";
import ExposureIcon from "@material-ui/icons/Exposure";

import Carousel, { Modal, ModalGateway } from "react-images";
import ImportKML from "../ImportKML.js";
//import RefreshIcon from '@material-ui/icons/Refresh';

import FarmersMap from "../FarmersMap.js";
import FieldList from "../FieldList.js";
import ReportsList from "../ReportsList.js";
import AddField from "../AddField.js";
import CreateNote from "../CreateNote.js";
import ReportComponent from "../ScoutingReportPage/ReportComponent.js";
import Select from "react-select";

import MessagingDialog from "../MessagingDialog";

import * as Helper from "../../Helper";

import { GoogleApiWrapper } from "google-maps-react";

import { withFirebase } from "../Firebase";

// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: 'green',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: 'green',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: 'red',
//     },
//     '&:hover fieldset': {
//       borderColor: 'yellow',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: 'green',
//     },
//   },
// });

// const RedditTextField = styled((props: TextFieldProps) => (
//   <TextField
//     InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiFilledInput-root': {
//     border: '1px solid #e2e2e1',
//     overflow: 'hidden',
//     borderRadius: 4,
//     backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
//     transition: theme.transitions.create([
//       'border-color',
//       'background-color',
//       'box-shadow',
//     ]),
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//     '&.Mui-focused': {
//       backgroundColor: 'transparent',
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }));

// const ValidationTextField = styled(TextField)({
//   '& input:valid + fieldset': {
//     borderColor: 'green',
//     borderWidth: 2,
//   },
//   '& input:invalid + fieldset': {
//     borderColor: 'red',
//     borderWidth: 2,
//   },
//   '& input:valid:focus + fieldset': {
//     borderLeftWidth: 6,
//     padding: '4px !important', // override inline-style
//   },
// });

class FieldAnalytics extends PureComponent {
  constructor() {
    super();
    this.state = {
      timelineValue: -1,
      FarmerUID: "",
      FarmerName: "",
      FieldID: "",
      memberType: "",
      cropVector: [],
      bounds: null,
      timelineArray: [],
      timelineArrayMilli: [],
      addFieldFlag: false,
      createNoteFlag: false,
      drawingManager: null,
      selectedFieldIndex: null,
      drawingPolyCoordinates: null,
      polygonShape: null,
      notePins: {},
      selectedPinID: null,
      modalSelection: null,
      userList: [],
      remoteIndex: "NDVI",
      remoteIndexLabel: "NDVI",
      scoutingReportsIDs: [],
      selectedReportIndex: null,
      scoutingReportPins: [],
      reportsArray: null,
      selectedReportKey: null,
      tileVisState: 1,
      lightboxIsOpen: false,
      searchText: "",
    };
  }

  componentDidMount() {
    if (this.props.menuType === "User")
      this.handleFarmerChange({
        UID: this.props.firebase.auth.currentUser.uid,
      });

    // this.props.firebase.getStorageImg(
    //   "remoteSensing/NDVI/CN7Z3TrZ2kWKuuHbSWRsS5EEymm1/1588600901956/1588400108000/",
    //   "index.html",
    //   true, url => {
    //     console.log(url);
    //   }
    // );
  }

  //   componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(([key, val]) =>
  //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(([key, val]) =>
  //       prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);

    const { FarmerNames, memberTypes, FarmerUIDs, menuType } = nextProps;

    let suggestions = null;

    if (
      menuType === "WGAdmin" &&
      FarmerNames &&
      this.state.userList.length != FarmerNames.length
    ) {
      suggestions = FarmerNames.map((name, index) => ({
        value: name,
        label: name,
        memberType: memberTypes[index],
        UID: FarmerUIDs[index],
      }));

      this.setState({ userList: suggestions });
    }

    if (
      nextProps &&
      menuType === "WGAdmin" &&
      FarmerNames &&
      FarmerUIDs &&
      nextProps.modalSelection != null &&
      (this.state.modalSelection == null ||
        nextProps.modalSelection.uid !== this.state.modalSelection.uid ||
        this.state.modalSelection == null ||
        nextProps.modalSelection.fieldID !== this.state.modalSelection.fieldID)
    ) {
      // console.log(nextProps.modalSelection);
      // console.log(this.state.modalSelection);
      // console.log("inside");

      let index = FarmerUIDs.indexOf(nextProps.modalSelection.uid);

      let picked;

      if (index == -1) {
        // console.log(nextProps.modalSelection.uid);
        picked = {
          value: null,
          label: null,
          memberType: null,
          UID: nextProps.modalSelection.uid,
        };
      } else
        picked = suggestions ? suggestions[index] : this.state.userList[index];

      this.handleFarmerChange({
        ...picked,
        fieldID: nextProps.modalSelection.fieldID,
      });
    }
  }

  componentWillUnmount() {
    if (this.cropListener) this.cropListener.off();
  }

  handleFarmerChange = (selectedOption) => {
    this.clearPins();

    if (this.cropListener) this.cropListener.off();

    if (selectedOption == null)
      this.setState((state) => ({
        FarmerName: "",
        FarmerUID: "",
        FieldID: "",
        FieldName: "",
        memberType: "",
        FarmerThumbUrl: null,
        cropVector: [],
        selectedFieldIndex: null,
        scoutingReportsIDs: [],
        selectedReportKey: null,
        modalSelection: null,
      }));
    else {
      //console.log(selectedOption);

      this.props.firebase
        .getStorageImg(
          "images/" +
            this.props.firebase.workgroupPath +
            "/" +
            selectedOption.UID +
            "/profilePic/",
          "thumb_img.jpg",
          true
        )
        .then((url) => {
          // console.log(url);

          this.cropListener = this.props.firebase.plotList(selectedOption.UID);

          this.cropListener.on("value", (result) => {
            let cropVector = [];

            if (result && result.val())
              cropVector = Object.values(result.val());

            let fieldInd = null;

            if (selectedOption.hasOwnProperty("fieldID"))
              for (let j = 0; j < cropVector.length; j++)
                if (
                  cropVector[j].hasOwnProperty("fieldID") &&
                  cropVector[j].fieldID.toString() === selectedOption.fieldID
                ) {
                  fieldInd = j;
                  break;
                }

            if (process.env.NODE_ENV) {
              let fieldsArea = 0;
              for (let j = 0; j < cropVector.length; j++)
                fieldsArea = fieldsArea + cropVector[j].fieldArea;
              console.log(fieldsArea.toString() + " acres");
            }

            this.setState(
              {
                FarmerName: selectedOption.value,
                memberType: selectedOption.memberType,
                FarmerUID: selectedOption.UID,
                FieldID: selectedOption.hasOwnProperty("fieldID")
                  ? selectedOption.fieldID
                  : "",
                FieldName: selectedOption.hasOwnProperty("fieldName")
                  ? selectedOption.fieldName
                  : "",
                selectedFieldIndex: fieldInd,
                FarmerThumbUrl: url,
                cropVector: cropVector,
                selectedReportKey: null,
                modalSelection: this.props.modalSelection,
              },
              () => {
                if (selectedOption.hasOwnProperty("fieldID")) {
                  if (!(fieldInd == null))
                    this.handleFieldSelected(fieldInd, null);
                }
              }
            );
          });
        });
    }
  };

  handleFieldSelected = (preIndex, fieldID) => {
    let index = null;

    if (preIndex != null) index = preIndex;
    else {
      for (let i = 0; i < this.state.cropVector.length; i++)
        if (fieldID === this.state.cropVector[i].fieldID) {
          index = i;
          break;
        }
    }

    var points;

    if (this.state.cropVector[index].hasOwnProperty("polygon")) {
      // console.log(this.state.cropVector[index]);
      points = this.state.cropVector[index].polygon[0].coordinates.map(
        (item) => {
          return { lat: item.latitude, lng: item.longitude };
        }
      );

      this.props.firebase
        .scoutingReportsDates(
          this.props.firebase.workgroupPath,
          this.state.FarmerUID
        )
        .once("value")
        .then((result) => {
          if (result.val()) {
            // console.log(result.val());
            let keys = Object.keys(result.val());
            let fieldKeys = [];
            for (let i = 0; i < keys.length; i++)
              if (
                keys[i].substring(13, 26) ===
                this.state.cropVector[index].fieldID.toString()
              )
                fieldKeys.push(keys[i]);
            this.setState({ scoutingReportsIDs: fieldKeys });
          } else this.setState({ scoutingReportsIDs: [] });
        });

      this.setState({
        selectedFieldIndex: index,
        FieldID: this.state.cropVector[index].fieldID,
        FieldName: this.state.cropVector[index].fieldName,
        bounds: points,
        selectedReportKey: null,
        selectedReportIndex: null,
        reportsArray: null,
        scoutingReportPins: [],
      });

      this.clearPins();
    } else {
      // console.log("no polygon");
    }
  };

  handleReportSelected = (index) => {
    if (index == this.state.selectedReportIndex) {
      this.setState({
        selectedReportIndex: null,
        reportsArray: null,
        scoutingReportPins: [],
      });
    } else {
      this.props.firebase
        .scoutingReportInfo(
          this.props.firebase.workgroupPath,
          this.state.FarmerUID,
          this.state.scoutingReportsIDs[index]
        )
        .once("value", (result) => {
          let reportsArray = result.val().reportsArray;
          let reportsArrayKeys = Object.keys(reportsArray);
          //console.log(reportsArray);

          let scoutingReportPins = [];
          for (let i = 0; i < reportsArrayKeys.length; i++) {
            scoutingReportPins.push({
              location: reportsArray[reportsArrayKeys[i]].location,
              key: reportsArrayKeys[i],
            });
          }

          this.setState({
            reportsArray: reportsArray,
            scoutingReportPins: scoutingReportPins,
          });
        });

      this.setState({ selectedReportIndex: index });
    }
  };

  handleScoutReportPinClick = (key) => {
    //console.log(key);
    this.setState({ selectedReportKey: key }); //Refers to a single pin and not the all scoutingReport
  };

  updateDatesArray = (datesArray, datesArrayMilli) => {
    // console.log("datesArrayMilli");
    // console.log(datesArrayMilli);
    // console.log("datesArray");
    // console.log(datesArray);

    let timelineValue;

    if (this.state.timelineValue == -1) {
      timelineValue = datesArrayMilli.length - 1;
    } else {
      let index = datesArrayMilli.indexOf(
        this.state.timelineArrayMilli[this.state.timelineValue]
      );
      timelineValue = index == -1 ? datesArrayMilli.length - 1 : index;
    }

    // console.log(timelineValue);

    this.setState({
      timelineArray: datesArray,
      timelineArrayMilli: datesArrayMilli,
      timelineValue: timelineValue,
    });
  };

  renderDate = (page) => {
    //console.log(page);
    return (
      <div>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#000000",
          }}
        >
          {this.state.timelineArray[this.state.timelineValue]}
        </Typography>
      </div>
    );
  };

  handleUpdateDrawerManager = (drawingManager) => {
    this.setState({ drawingManager: drawingManager });
  };

  handleAddClick = () => {
    //console.log("add");
    this.state.drawingManager.setDrawingMode(
      this.props.google.maps.drawing.OverlayType.POLYGON
    );
    this.setState({ addFieldFlag: true });
  };

  handleAddClose = () => {
    //console.log("close");
    // Delete the polygon
    if (this.state.addFieldFlag == true) {
      if (this.state.polygonShape) {
        this.state.polygonShape.setMap(null);
        this.updateCoords(null, null);
      }

      this.state.drawingManager.setDrawingMode(null);
      this.setState({ addFieldFlag: false });
    } else if (this.state.createNoteFlag == true)
      this.setState({ createNoteFlag: false });
  };

  updateCoords = (polygonShape, coordinates) => {
    this.setState({
      polygonShape: polygonShape,
      drawingPolyCoordinates: coordinates,
    });
  };

  updatePins = (id, marker, coordinates) => {
    let notePins = { ...this.state.notePins };
    notePins[id] = { marker: marker, coordinates: coordinates };
    //console.log(notePins);
    this.setState({ notePins: notePins });
  };

  deletePin = () => {
    // console.log(this.state.notePins);
    // console.log(this.state.selectedPinID);
    this.state.notePins[this.state.selectedPinID].marker.setMap(null);
    let notePins = { ...this.state.notePins };
    delete notePins[this.state.selectedPinID];
    this.setState({ notePins: notePins, selectedPinID: null });
  };

  clearPins = () => {
    for (let pin in this.state.notePins)
      this.state.notePins[pin].marker.setMap(null);

    this.setState({ notePins: {}, selectedPinID: null });

    if (
      this.state.hasOwnProperty("drawingManager") &&
      this.state.drawingManager
    )
      this.state.drawingManager.setDrawingMode(null);
  };

  handleSaveField = (info) => {
    console.log(info);

    let thing = {
      fieldName: info.fieldName,
      fieldID: info.fieldID,
      crop: info.cropName,
      variety: info.variety,
      fieldArea: info.fieldArea,
      organicType: info.fieldType, //"Organic" : "Nonorganic",
      markerPosition: info.marker,
      polygon: info.polygon,
    };

    this.props.firebase.addField(this.state.FarmerUID, thing, info.marker);

    this.handleAddClose();
  };

  handleDeleteClick = () => {
    this.props.firebase.deleteField(
      this.state.FarmerUID,
      this.state.cropVector[this.state.selectedFieldIndex].fieldID
    );
  };

  handleOpenNDVIModal = () => {
    this.props.handleOpenNDVIModal(
      this.state.FarmerUID,
      this.state.FieldID,
      this.state.FieldName
    );
  };

  handleCreateNote = () => {
    this.state.drawingManager.setDrawingMode(
      this.props.google.maps.drawing.OverlayType.MARKER
    );
    this.setState({ createNoteFlag: true });
  };

  handleSelectPin = (pinID) => {
    this.setState({ selectedPinID: pinID });
  };

  handleSendNote = (message) => {
    let nowDate = new Date();
    var sharedDate = Helper.dateToString(nowDate);
    let dateForSorting = nowDate * 1;

    let fromUID = this.props.firebase.auth.currentUser.uid;
    let toUID = this.state.FarmerUID;
    let fieldID = this.state.FieldID;
    let selectedDate = this.state.timelineArrayMilli[this.state.timelineValue];
    let polygon =
      this.state.cropVector[this.state.selectedFieldIndex].polygon[0];
    let pins = {};

    for (let pin in this.state.notePins)
      pins[pin] = this.state.notePins[pin].coordinates;

    // write to workgroupPath ndviNotes
    // write to user's shared with message

    let noteID = "Note" + toUID + fieldID + dateForSorting;

    this.props.firebase._setDatabase(
      this.props.firebase.workgroupPath +
        "/user/" +
        toUID +
        "/sharedWithMe/" +
        fromUID +
        noteID,
      {
        date: sharedDate,
        dateForSorting: dateForSorting,
        imageName: noteID,
        seen: false,
        sharingUser: fromUID,
        text: message,
        fieldID: fieldID,
        polygon: polygon,
        scanDate: selectedDate,
        pins: pins,
        modality: "sen2",
        remoteIndex: this.state.remoteIndex,
      },
      1000
    );

    this.props.firebase._setDatabase(
      this.props.firebase.workgroupPath +
        "/user/" +
        fromUID +
        "/answers/" +
        fromUID +
        noteID,
      {
        answerDate: sharedDate,
        answeringUser: toUID,
        dateForSorting: dateForSorting,
        imageName: noteID,
        seen: true,
        sharingUser: fromUID,
        fieldID: fieldID,
        polygon: polygon,
        scanDate: selectedDate,
        pins: pins,
        modality: "sen2",
        remoteIndex: this.state.remoteIndex,
        text: message, //TODO this is a new field that will be used in the future to read all the thread from the "answer" node
      },
      1000
    );

    this.props.firebase._setDatabase(
      this.props.firebase.workgroupPath +
        "/sentNotes/" +
        toUID +
        "/" +
        fieldID +
        "/" +
        +selectedDate,
      {
        noteID: noteID,
        dateForSorting: dateForSorting,
        imageName: noteID,
        sharingUser: fromUID,
        text: message,
        fieldID: fieldID,
        polygon: polygon,
        scanDate: selectedDate,
        pins: pins,
        modality: "sen2",
        remoteIndex: this.state.remoteIndex,
      },
      1000
    );

    this.setState({ createNoteFlag: false });
    this.clearPins();
  };

  handleSelectRemoteIndex = (remoteIndex) => {
    //console.log(remoteIndex);
    this.setState({
      remoteIndex: remoteIndex.value,
      remoteIndexLabel: remoteIndex.label,
    });
  };

  userSelector = (classes) => {
    if (this.props.menuType === "WGAdmin")
      return (
        <div
          style={{
            position: "absolute",
            top: 10,
            width: 250,
            marginLeft: 12,
            zIndex: 5,
          }}
        >
          <Paper
            className={classes.paper}
            style={{ minHeight: 120, height: "10vh", overflow: "visible" }}
          >
            <div style={{ zIndex: 100, backgroundColor: "green" }}>
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              >
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Select
                        MenuProps={{
                          style: { zIndex: 35001 },
                        }}
                        options={this.state.userList}
                        value={{
                          label: this.state.FarmerName,
                          value: this.state.FarmerName,
                        }}
                        maxMenuHeight={240}
                        onChange={this.handleFarmerChange}
                        placeholder="Search by nickname"
                        isClearable
                      />
                    </Grid>
                  </Grid>
                  {this.props.workgroupID == null &&
                    this.props.menuType === "WGAdmin" &&
                    this.props.notCopying && (
                      <IconButton
                        onClick={this.props.copyPremiumData}
                        color="inherit"
                        size="tiny"
                      >
                        <RefreshIcon
                          fontSize="small"
                          className={classes.block}
                          color="action"
                        />
                      </IconButton>
                    )}
                </Toolbar>
              </AppBar>
            </div>
            <div className={classes.contentWrapper}>
              {this.state.FarmerUID === "" ? (
                <Typography color="textSecondary" align="center">
                  No user was selected
                </Typography>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    marginTop: 6,
                    marginLeft: 16,
                  }}
                >
                  <div
                    style={{
                      flex: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={
                        this.state.FarmerThumbUrl == "Not Found"
                          ? require("../../Assests/user_icon.png")
                          : this.state.FarmerThumbUrl
                      }
                      style={{ borderRadius: 25 }}
                      width={50}
                      height={50}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: 100,
                      marginLeft: 8,
                      flex: 1,
                      justifyContent: "flex-start",
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ noWrap: true }}
                      primary={this.state.FarmerName}
                      secondary={this.state.memberType}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.5,
                      marginRight: 16,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      onClick={() => this.messagingRef.handleMessagingOpen()}
                      color="inherit"
                    >
                      <EmailIcon className={classes.block} color="action" />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </Paper>
        </div>
      );
    else return null;
  };

  handleVisOff = () => {
    this.setState({ tileVisState: 0 });
  };

  handleVisNormal = () => {
    this.setState({ tileVisState: 1 });
  };

  handleVisDiff = () => {
    this.setState({ tileVisState: 2 });
  };

  toggleLightbox = () => {
    this.setState({ lightboxIsOpen: !this.state.lightboxIsOpen });
  };

  // <div style={{height: "100px"}}>
  //   <HorizontalTimeline
  //     styles={{ background: 'transparent', foreground: '#ffffff', outline: '#ffffff' }}
  //     index={this.state.timelineValue}
  //     indexClick={(index) => {
  //       this.setState({ timelineValue: index, previous: this.state.timelineValue });
  //     }}
  //     values={ this.state.timelineArray } />
  // </div>

  // <Button disabled={false} onClick={this.handleAddClick} style={{marginLeft: 185, fontSize: 10, font: "Roboto", color:"textSecondary", backgroundColor: "transparent", borderRadius: 4, width: "32px", height: "32px"}} variant="contained">
  //
  // </Button>

  addToPlanet = () => {
    if (window.confirm("Are you sure you want to do this?")) {
      const date = new Date(); // creates a new Date object with the current date and time
      const year = date.getFullYear(); // gets the year (e.g. 2022)
      const month = date.getMonth() + 1; // gets the month (0-11), so we add 1 to get the correct month number (1-12)
      const day = date.getDate(); // gets the day of the month (1-31)
      const isoString_from = new Date(year, month - 1, day - 2).toISOString(); // creates a new Date object with the specified year, month, and day, and then converts it to the ISO string format
      const isoString_to = new Date(year + 1, month - 1, day - 3).toISOString(); // it should work well even if it is the first day of the month

      //TODO what about holes
      let coordinates =
        this.state.cropVector[this.state.selectedFieldIndex].polygon[0]
          .coordinates;

      let coordinatesArray = [];

      for (let i = 0; i < coordinates.length; i++)
        coordinatesArray.push([
          coordinates[i].longitude,
          coordinates[i].latitude,
        ]);

      coordinatesArray.push([
        coordinates[0].longitude,
        coordinates[0].latitude,
      ]);

      let curlJSON = {
        input: {
          provider: "PLANET",
          planetApiKey: "PLAK9587f255195f4b369755d562059bd2e2",
          bounds: {
            geometry: {
              type: "Polygon",
              coordinates: [coordinatesArray],
            },
          },
          data: [
            {
              itemType: "PSScene",
              productBundle: "analytic_8b_sr_udm2",
              dataFilter: {
                timeRange: {
                  from: isoString_from, //"2022-08-05T00:00:00Z",
                  to: isoString_to, //"2023-08-04T23:59:59Z",
                },
                maxCloudCoverage: 100,
              },
              harmonizeTo: "Sentinel-2",
            },
          ],
        },
        collectionId: "405a431d-175c-4fcc-8dd3-609ef398d2b0",
        name: "PlanetScope",
      };

      // console.log(
      //   "curl -X POST https://services.sentinel-hub.com/api/v1/dataimport/search \
      // -H 'Content-Type: application/json' \
      // -H 'Authorization: Bearer eyJraWQiOiJzaCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI4ZmY2MTk0YS00ZjY2LTRiN2EtOTgzMi0wNjQ2MTNlOTg4MjgiLCJhdWQiOiIwZjYxZDM4NS01YzhiLTQ0ZjktYTE1NC0yYWRmNTFkNTAxNDEiLCJqdGkiOiJmMWYyMDIzZi02OGM2LTRjOWEtOGVhYy05MTZiNWEwNTY2MTgiLCJleHAiOjE2NzE2NTQwMDMsIm5hbWUiOiJOZXNzaSBCZW5pc2h0aSIsImVtYWlsIjoibmVzc2lAc2FpbGxvZy5jbyIsImdpdmVuX25hbWUiOiJOZXNzaSIsImZhbWlseV9uYW1lIjoiQmVuaXNodGkiLCJzaWQiOiJkMTE2M2Q2Yy05MTdhLTQ3NzMtODk4Mi00OTUzNDc4NjM2ZTUiLCJkaWQiOjEsImFpZCI6Ijc4ZDEzNzNiLTcyYWUtNDFlOS1iYzIxLWJkNDI0NDhmM2ZmMCIsImQiOnsiMSI6eyJyYSI6eyJyYWciOjF9LCJ0IjoxMzAwMH19fQ.gNq04hbmGOpQUcAIUynswWRH_Fzdndyjwh1U4qvhR7ol6zdMHbvt2xXXbXcNj8TRklCmMkpRhrc9LxZEotnRC2UNS2vjM-XlIDeYtwWxcb8ajmRHwOjuL-rjfTvPTe-VxDqTJ_gMA9a7bVpkQKeTFtf2p8Xp8Qw8jEDVQdHvbcxd_Ysh0FcHAxboeIrf6j5FhTPwXKmRoab5tl90jMaroujTmPIE0hbgqS9MRBe6vV802cp1JTpAhN1FvsqUj-imf6_Kurmgh28NIIeVLSbGcc5XxDlGnROJ5iDNDafFYkKI-N-ryoR5Q2F3ZxpFYD61K-uslvfJXeZT8mGbo9szTA' \
      // -d '" +
      //     JSON.stringify(curlJSON) +
      //     "'"
      // );

      fetch(
        "https://services.sentinel-hub.com/api/v1/dataimport/subscriptions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ3dE9hV1o2aFJJeUowbGlsYXctcWd4NzlUdm1hX3ZKZlNuMW1WNm5HX0tVIn0.eyJleHAiOjE3MzQ1OTEwNDksImlhdCI6MTczNDU4NzQ0OSwiYXV0aF90aW1lIjoxNzM0NTg3NDQ4LCJqdGkiOiI3MmZhOTM1MC1lOGUyLTQzMTUtODNkNC0zMjBmNzJiNzBhMmUiLCJpc3MiOiJodHRwczovL3NlcnZpY2VzLnNlbnRpbmVsLWh1Yi5jb20vYXV0aC9yZWFsbXMvbWFpbiIsInN1YiI6IjhmZjYxOTRhLTRmNjYtNGI3YS05ODMyLTA2NDYxM2U5ODgyOCIsInR5cCI6IkJlYXJlciIsImF6cCI6IjBmNjFkMzg1LTVjOGItNDRmOS1hMTU0LTJhZGY1MWQ1MDE0MSIsIm5vbmNlIjoiMWQ3ODM5NjktZjVlNy00MmRjLThmNWYtOTYwYWM5ZjVlYjA3Iiwic2Vzc2lvbl9zdGF0ZSI6Ijk3ZjAzM2Q4LWRiMWMtNDYzYy05ZGY4LTU3OGU0OWQ0OGQzMiIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2FwcHMuc2VudGluZWwtaHViLmNvbSIsImh0dHBzOi8vc3RhdGljLnByb2QucGxhbmV0LWxhYnMuY29tIl0sInNjb3BlIjoib3BlbmlkIGVtYWlsIHByb2ZpbGUiLCJzaWQiOiI5N2YwMzNkOC1kYjFjLTQ2M2MtOWRmOC01NzhlNDlkNDhkMzIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6Ik5lc3NpIEJlbmlzaHRpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibmVzc2lAc2FpbGxvZy5jbyIsImdpdmVuX25hbWUiOiJOZXNzaSIsImZhbWlseV9uYW1lIjoiQmVuaXNodGkiLCJlbWFpbCI6Im5lc3NpQHNhaWxsb2cuY28iLCJhY2NvdW50IjoiNzhkMTM3M2ItNzJhZS00MWU5LWJjMjEtYmQ0MjQ0OGYzZmYwIn0.nG25wKqXM1vDZ4IyYGREEa3pRZ79x_era1JE8M8Yf-hyAE_cf92NwK5iWLSmbZg-vrAPUmKs5Zxf7xj7mcSzLWX9h0dRxTY_8Z3MH6h3fno-40y-Ax88_KdGp-j0-ilgFbstcYqn0uv9wQMLxphkSIeo0ACkBdTqz2QItKXrKGnlRwtDoVETrTXH9rWCib6wiaMv1DH66GtOnT61oYSrkJO901nX7CPi51DdRrCuES1E0MBlAianyXHpV2q4HnYlPmh9qZyPXvt3VmPrQqJYclwxvkDDHbt8uJudJYoRrktjAJURK_vElXZug9MZh_Ec-3BKBBiXxH53k4_4KSrdcQ",
          },
          body: JSON.stringify(curlJSON),
        }
      )
        .then((response) => {
          this.props.firebase.db
            .ref(this.props.firebase.workgroupPath + "/plotList/")
            .child(this.state.FarmerUID)
            .child(
              this.state.cropVector[
                this.state.selectedFieldIndex
              ].fieldID.toString()
            )
            .child("satelliteProvider")
            .set("PlanetScope");

          response.json();
        })
        .then((data) => console.log(data))
        .catch((error) => console.error(error));
    }
  };

  //function FieldAnalytics(props) {
  render() {
    // const { classes, handleFarmerChange, FarmerName, FarmerNames, FarmerUIDs, ListItems } = props;
    const { classes, FarmerUIDs } = this.props;
    //console.log(this.state.scoutingReportsIDs);
    const style = {
      control: (base) => ({
        ...base,
        width: 160,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };
    //console.log(this.state.userList);
    //console.log(this.state.reportsArray);
    //console.log(this.state.selectedReportKey);

    const reportInfo =
      this.state.selectedReportKey != null
        ? this.state.reportsArray[this.state.selectedReportKey]
        : null;
    const reportKey =
      this.state.selectedReportKey != null
        ? this.state.selectedReportKey
        : null;

    return (
      <div
        style={{
          position: "relative",
          height: "100vh",
        }}
      >
        <FarmersMap
          google={this.props.google}
          FarmerUIDs={FarmerUIDs}
          bounds={this.state.bounds}
          selectedDate={
            this.state.timelineArrayMilli.length > 0
              ? this.state.timelineArrayMilli[this.state.timelineValue]
              : null
          }
          previousDate={
            this.state.timelineArrayMilli.length > 0 &&
            this.state.timelineValue > 0
              ? this.state.timelineArrayMilli[this.state.timelineValue - 1]
              : null
          }
          callback={this.handleFarmerChange}
          updateDatesArray={this.updateDatesArray}
          drawerCallback={this.handleUpdateDrawerManager}
          updateCoords={this.updateCoords}
          updatePins={this.updatePins}
          handleSelectPin={this.handleSelectPin}
          remoteIndex={this.state.remoteIndex}
          scoutingReportPins={this.state.scoutingReportPins}
          scoutPinCallback={this.handleScoutReportPinClick}
          menuType={this.props.menuType}
          tileVisState={this.state.tileVisState}
        />

        {this.userSelector(classes)}

        {this.state.FarmerUID !== "" && (
          <div
            style={{
              position: "absolute",
              top: this.props.menuType === "WGAdmin" ? 170 : 10,
              width: 250,
              marginLeft: 12,
              zIndex: 4,
            }}
          >
            <Paper
              style={{
                minHeight: 300,
                height: "45vh",
                maxHeight: "45vh",
              }}
            >
              <AppBar
                className={classes.searchBar}
                position="sticky"
                color="default"
                elevation={0}
              >
                {this.state.addFieldFlag == false &&
                  this.state.createNoteFlag == false && (
                    <div
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginLeft: 12,
                        }}
                      >
                        <IconButton
                          disabled={this.state.FieldID === ""}
                          onClick={this.handleOpenNDVIModal}
                          color="inherit"
                        >
                          <CompareIcon
                            className={classes.searchBar}
                            color={
                              this.state.FieldID === "" ? "disabled" : "action"
                            }
                          />
                        </IconButton>

                        <IconButton
                          disabled={
                            this.state.drawingManager == null ||
                            this.state.FieldID === ""
                          }
                          onClick={this.handleCreateNote}
                          color="inherit"
                        >
                          <LibraryBooksIcon
                            className={classes.searchBar}
                            color={
                              this.state.drawingManager == null ||
                              this.state.FieldID === ""
                                ? "disabled"
                                : "action"
                            }
                          />
                        </IconButton>

                        <IconButton
                          disabled={this.state.FieldID === ""}
                          onClick={() =>
                            this.props.handleWeatherDrawerToggle(
                              this.state.FarmerUID,
                              this.state.FieldID
                            )
                          }
                          color="inherit"
                        >
                          <CloudIcon
                            className={classes.searchBar}
                            color={
                              this.state.FieldID === "" ? "disabled" : "action"
                            }
                          />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          marginRight: 12,
                        }}
                      >
                        {this.state.selectedFieldIndex != null && (
                          <IconButton
                            disabled={this.state.selectedFieldIndex == null}
                            onClick={this.handleDeleteClick}
                            color="inherit"
                          >
                            <DeleteForeverIcon
                              className={classes.searchBar}
                              color={
                                this.state.selectedFieldIndex == null
                                  ? "disabled"
                                  : "action"
                              }
                            />
                          </IconButton>
                        )}

                        <IconButton
                          disabled={this.state.drawingManager == null}
                          onClick={this.handleAddClick}
                          color="inherit"
                        >
                          <AddIcon
                            className={classes.searchBar}
                            color={
                              this.state.drawingManager == null
                                ? "disabled"
                                : "action"
                            }
                          />
                        </IconButton>
                        {this.props.firebase.auth.currentUser.uid ===
                          "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" &&
                          this.state.selectedFieldIndex == null && (
                            <IconButton
                              disabled={false}
                              onClick={this.toggleLightbox}
                              color="inherit"
                              size="small"
                            >
                              <ImportExportIcon
                                className={classes.searchBar}
                                color={"action"}
                              />
                            </IconButton>
                          )}
                        {this.props.firebase.auth.currentUser.uid ===
                          "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" &&
                          this.state.selectedFieldIndex != null && (
                            <IconButton
                              disabled={false}
                              onClick={this.addToPlanet}
                              color="inherit"
                              size="small"
                            >
                              <LibraryBooksIcon
                                className={classes.searchBar}
                                color={"action"}
                              />
                            </IconButton>
                          )}
                      </div>
                    </div>
                  )}
                {(this.state.addFieldFlag == true ||
                  this.state.createNoteFlag == true) && (
                  <IconButton
                    disabled={false}
                    onClick={this.handleAddClose}
                    color="inherit"
                  >
                    <CloseIcon className={classes.block} color="action" />
                  </IconButton>
                )}
              </AppBar>
              {this.state.addFieldFlag == false &&
                this.state.FarmerUID !== "" &&
                this.state.cropVector.length == 0 && (
                  <Typography
                    style={{ marginTop: 12 }}
                    color="textSecondary"
                    align="center"
                  >
                    No fields were defined
                  </Typography>
                )}
              {this.state.addFieldFlag == false &&
                this.state.createNoteFlag == false &&
                this.state.cropVector.length > 0 && (
                  <FieldList
                    Entries={this.state.cropVector}
                    selected={this.state.selectedFieldIndex}
                    searchText={this.state.searchText}
                    callback={this.handleFieldSelected}
                  />
                )}
              {this.state.addFieldFlag && (
                <AddField
                  updateCoords={this.updateCoords}
                  google={this.props.google}
                  polygonShape={this.state.polygonShape}
                  drawingManager={this.state.drawingManager}
                  drawingPolyCoordinates={this.state.drawingPolyCoordinates}
                  saveField={this.handleSaveField}
                  classes={classes}
                />
              )}
              {this.state.createNoteFlag && (
                <CreateNote
                  google={this.props.google}
                  drawingManager={this.state.drawingManager}
                  selectedPinID={this.state.selectedPinID}
                  uid={this.state.FarmerUID}
                  handleDeletePin={this.deletePin}
                  handleSendNote={this.handleSendNote}
                  classes={classes}
                />
              )}
              <div style={{ height: "3vh", maxHeight: "3vh" }}>
                <TextField
                  autoComplete="off"
                  disableUnderline={true}
                  inputProps={{
                    style: { backgroundColor: "#efefef" },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { color: "#80868B" },
                  }}
                  type="search"
                  id="Search"
                  label="Search"
                  fullWidth
                  rowsMax={1}
                  size={"small"}
                  defaultValue=""
                  onChange={(event) => {
                    this.setState({ searchText: event.target.value });
                  }}
                  value={this.state.searchText}
                  variant="filled"
                />
              </div>
            </Paper>
          </div>
        )}

        {this.state.selectedFieldIndex !== null && (
          <div
            style={{
              position: "absolute",
              top: this.props.menuType === "WGAdmin" ? 605 : 445,
              width: 250,
              marginLeft: 12,
              zIndex: 4,
            }}
          >
            <Paper
              style={{
                minHeight: 100,
                height: "20vh",
                maxHeight: "20vh",
                overflow: "auto",
              }}
            >
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              ></AppBar>
              {this.state.scoutingReportsIDs.length == 0 && (
                <Typography
                  style={{ marginTop: 12 }}
                  color="textSecondary"
                  align="center"
                >
                  No reports
                </Typography>
              )}
              {this.state.scoutingReportsIDs.length > 0 && (
                <ReportsList
                  Entries={this.state.scoutingReportsIDs}
                  selected={this.state.selectedReportIndex}
                  callback={this.handleReportSelected}
                />
              )}
            </Paper>
          </div>
        )}

        {this.state.selectedReportKey !== null && (
          <div
            style={{
              position: "absolute",
              bottom: 50,
              width: 350,
              left: 300,
              zIndex: 4,
            }}
          >
            <Paper style={{ maxHeight: 320, overflow: "auto" }}>
              <AppBar
                className={classes.searchBar}
                position="static"
                color="default"
                elevation={0}
              ></AppBar>

              <ReportComponent
                key={reportKey}
                uid={this.state.FarmerUID}
                reportInfo={reportInfo}
                scoutingReportID={
                  this.state.scoutingReportsIDs[this.state.selectedReportIndex]
                }
                reportKey={reportKey}
                onClose={() => this.setState({ selectedReportKey: null })}
              />
            </Paper>
          </div>
        )}

        <div
          style={{
            position: "absolute",
            top: 10,
            right: 260,
            marginLeft: 12,
            zIndex: 5,
          }}
        >
          {/* Bounding box for the Timeline */}
          <Paper
            square={true}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              width: 160,
              overflow: "display",
            }}
          >
            <Select
              label="Single select"
              styles={style}
              MenuProps={{
                style: { zIndex: 35001 },
              }}
              options={[
                { value: "NDVI", label: "NDVI" },
                { value: "redEdge", label: "CHLOROPHYLL" },
              ]}
              value={{
                label: this.state.remoteIndexLabel,
                value: this.state.remoteIndexLabel,
              }}
              maxMenuHeight={240}
              onChange={this.handleSelectRemoteIndex}
            />
          </Paper>
        </div>

        {this.state.timelineArray.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 440,
              marginLeft: 12,
              zIndex: 5,
            }}
          >
            {/* Bounding box for the Timeline */}
            <Paper
              square={true}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 40,
                width: 160,
                overflow: "auto",
              }}
            >
              <Pagination
                count={this.state.timelineArray.length}
                defaultPage={this.state.timelineValue + 1}
                siblingCount={0}
                onChange={(event, page) => {
                  this.setState({
                    timelineValue: page - 1,
                  });
                }}
                renderItem={(item) => {
                  if (
                    item.type === "page" &&
                    item.page == this.state.timelineValue + 1
                  )
                    return (
                      <PaginationItem
                        {...item}
                        component={() => this.renderDate(item.page)}
                      />
                    );
                  else if (item.type === "previous" || item.type === "next")
                    return <PaginationItem {...item} />;
                  else return null;
                }}
              />
            </Paper>
          </div>
        )}

        <div
          style={{
            position: "absolute",
            top: 450,
            right: 10,
            marginLeft: 12,
            zIndex: 5,
          }}
        >
          {/* Bounding box for the Timeline */}
          <Paper
            square={true}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 150,
              width: 40,
              overflow: "display",
            }}
          >
            <div style={{ marginVertical: 20 }}>
              <IconButton
                onClick={this.handleVisOff}
                color="inherit"
                size="tiny"
              >
                <LayersClearIcon
                  fontSize="small"
                  className={classes.block}
                  color={this.state.tileVisState == 0 ? "black" : "action"}
                />
              </IconButton>
            </div>
            <div style={{ marginTop: 20 }}>
              <IconButton
                onClick={this.handleVisNormal}
                color="inherit"
                size="tiny"
              >
                <LayersIcon
                  fontSize="small"
                  className={classes.block}
                  color={this.state.tileVisState == 1 ? "black" : "action"}
                />
              </IconButton>
            </div>
            <div style={{ marginTop: 20 }}>
              <IconButton
                onClick={this.handleVisDiff}
                color="inherit"
                size="tiny"
              >
                <ExposureIcon
                  fontSize="small"
                  className={classes.block}
                  color={this.state.tileVisState == 2 ? "black" : "action"}
                />
              </IconButton>
            </div>
          </Paper>
        </div>

        <MessagingDialog
          targetUID={this.state.FarmerUID}
          onRef={(actualChild) => (this.messagingRef = actualChild)}
        />
        <ModalGateway>
          {this.state.lightboxIsOpen && (
            <Modal onClose={this.toggleLightbox}>
              <ImportKML saveField={this.handleSaveField} />
            </Modal>
          )}
        </ModalGateway>
      </div>
    );
  }
}

// FieldAnalytics.propTypes = {
//   classes: PropTypes.object.isRequired,
//   handleFarmerChange: PropTypes.func.isRequired,
//   FarmerName: PropTypes.string.isRequired,
//   FarmerNames: PropTypes.array.isRequired,
// };

//export default withStyles(styles)(FieldAnalytics);
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY,
  libraries: ["drawing"],
})(withFirebase(FieldAnalytics));
