import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Navigator from "./Navigator";
import NotificationDrawer from "./NotificationDrawer";
import ChatDrawer from "./ChatDrawer";
import WeatherDrawer from "./WeatherDrawer";
import Header from "./Header";
import NDVIModal from "../NDVIModal";
import RemoteSensing from "../RemoteSensing/RemoteSensing";
import TreatmentPage from "../TreatmentPage";
import WorkgroupPage from "../WorkgroupPage";
import OnBoarding from "../OnBoarding";
import * as Helper from "../../Helper";
import MessagingDialog from "../MessagingDialog";
import WorkgroupRequestDialog from "../WorkgroupRequestDialog";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

const drawerWidth = 256;
const notificationDrawerWidth = 512;
const chatDrawerWidth = "80%";
const weatherDrawerWidth = 440;

const styles = {
  root: {
    display: "flex",
    minHeight: "75vh",
  },
  drawer: {
    flexShrink: 0,
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: "0px 0px 0",
    background: "#eaeff1",
  },
  paper: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRightStyle: "solid",
      borderRightWidth: 1,
      borderRightColor: "#000000",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
};

class Base extends React.Component {
  constructor() {
    super();

    this.state = {
      mobileOpen: false,
      notificationDrawerOpen: false,
      chatDrawerOpen: false,
      weatherDrawerOpen: false,
      user_profile_image_uri: "Not Found",
      notifications: {},
      unReadNotification: 0,
      unReadNotificationOtherWG: 0,
      numUnReadNotificationOtherWG: {},

      chatRooms: [],
      unReadChatMessages: 0,
      unReadChatMessagesOtherWG: 0,
      numUnReadChatMessagesOtherWG: {},

      selectedTab: 0,
      workgroupLogo: "Not Found",
      modalSelection: null,
      numCloudyNotifs: 0,
      numNDVINotifs: 0,
      selectedUID: null,
      selectedFieldID: null,
      tempUnits: "C",
    };
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleNotificationDrawerToggle = () => {
    this.setState((state) => ({
      notificationDrawerOpen: !state.notificationDrawerOpen,
    }));
  };

  handleChatDrawerToggle = () => {
    this.setState((state) => ({
      chatDrawerOpen: !state.chatDrawerOpen,
    }));
  };

  handleWeatherDrawerToggle = () => {
    this.setState((state) => ({
      weatherDrawerOpen: !state.weatherDrawerOpen,
    }));
  };

  handleOpenWeatherDrawer = (uid, fieldID) => {
    this.setState((state) => ({
      weatherDrawerOpen: true,
      selectedUID: uid,
      selectedFieldID: fieldID,
    }));
  };

  componentDidMount() {
    this.props.firebase
      .getStorageImg(
        "images/" + this.props.firebase.workgroupPath + "/misc",
        "logo.jpg",
        true
      )
      .then((url) => {
        //console.log("url: ",url);
        this.setState({
          workgroupLogo: url,
        });
      });

    this.props.firebase
      .getStorageImg(
        "images/" +
          this.props.firebase.workgroupPath +
          "/" +
          this.props.firebase.auth.currentUser.uid +
          "/profilePic/",
        "thumb_img.jpg",
        true
      )
      .then((url) => {
        //console.log(url);
        this.setState({
          user_profile_image_uri: url,
        });
      });

    //this.handleTabChange({},0);

    //Fetch notifications

    this.props.firebase.db
      .ref("/workgroupAffiliation")
      .child(this.props.firebase.auth.currentUser.uid)
      .once("value")
      .then((snap) => {
        let WGoptions = snap.val()
          ? snap.val().hasOwnProperty("options")
            ? [""].concat(snap.val().options)
            : [this.props.firebase.workgroupID]
          : [];

        this.notificationsListener = {};
        this.chatListener = {};
        let numUnReadNotificationOtherWG = {};
        let numUnReadChatMessagesOtherWG = {};

        for (let j = 0; j < WGoptions.length; j++) {
          let workgroupPath =
            WGoptions[j] === "" ? "" : "workgroups/" + WGoptions[j];

          this.notificationsListener[workgroupPath] = this.props.firebase
            .notificationsReference(workgroupPath)
            .limitToLast(200);

          this.notificationsListener[workgroupPath].on("value", (result) => {
            if (result.val()) {
              let notifications = result.val();

              let numUnRead = 0;
              let keys = Object.keys(notifications);

              for (let i in keys)
                if (notifications[keys[i]].read == false)
                  numUnRead = numUnRead + 1;

              if (workgroupPath === this.props.firebase.workgroupPath)
                this.setState({
                  notifications: result.val(),
                  unReadNotification: numUnRead,
                });
              else {
                numUnReadNotificationOtherWG[workgroupPath] = numUnRead;
                let tmp = Object.values(numUnReadNotificationOtherWG).reduce(
                  (a, b) => a + b,
                  0
                );
                // console.log(numUnReadNotificationOtherWG);
                // console.log(tmp);
                this.setState({
                  numUnReadNotificationOtherWG: numUnReadNotificationOtherWG,
                  unReadNotificationOtherWG: tmp,
                });
              }
            } else {
              numUnReadNotificationOtherWG[workgroupPath] = 0;
              let tmp = Object.values(numUnReadNotificationOtherWG).reduce(
                (a, b) => a + b,
                0
              );
              this.setState({
                numUnReadNotificationOtherWG: numUnReadNotificationOtherWG,
                unReadNotificationOtherWG: tmp,
              });
            }
          });

          this.chatListener[workgroupPath] = this.props.firebase
            .chatReference(workgroupPath)
            .orderByChild("dateForSorting")
            .limitToLast(200);

          this.chatListener[workgroupPath].on("value", (result) => {
            if (result.val()) {
              let keys = Object.keys(result.val());
              let values = Object.values(result.val());

              for (let i = 0; i < values.length; i++) {
                let split = keys[i].split("_");
                values[i]["id"] = keys[i];
                values[i]["type"] =
                  split[0] === "OneOnOne" ? "oneonone" : "group";
              }

              let chatRooms = Helper.sortByValue(
                values,
                "dateForSorting",
                "num"
              );

              let numUnRead = 0;

              for (let i in chatRooms)
                if (chatRooms[i].read == false) numUnRead = numUnRead + 1;

              if (workgroupPath === this.props.firebase.workgroupPath)
                this.setState({
                  chatRooms: chatRooms,
                  unReadChatMessages: numUnRead,
                });
              else {
                numUnReadChatMessagesOtherWG[workgroupPath] = numUnRead;
                let tmp = Object.values(numUnReadChatMessagesOtherWG).reduce(
                  (a, b) => a + b,
                  0
                );

                this.setState({
                  numUnReadChatMessagesOtherWG: numUnReadChatMessagesOtherWG,
                  unReadChatMessagesOtherWG: tmp,
                });
              }
            } else {
              numUnReadChatMessagesOtherWG[workgroupPath] = 0;
              let tmp = Object.values(numUnReadChatMessagesOtherWG).reduce(
                (a, b) => a + b,
                0
              );
              this.setState({
                numUnReadChatMessagesOtherWG: numUnReadChatMessagesOtherWG,
                unReadChatMessagesOtherWG: tmp,
              });
            }
          });
        }
      });
    ///

    this.props.firebase.setLastOnline();

    if (
      this.props.firebase.auth.currentUser.uid ===
      "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
    ) {
      this.listenrCloudNotif = this.props.firebase.db.ref(
        "FBfunctionJobs/RemoteCloudyNotifications"
      );
      this.listenrCloudNotif.on("value", (result) => {
        //console.log(result.val());
        this.setState({
          numCloudyNotifs: result.val() ? Object.keys(result.val()).length : 0,
        });
      });

      this.listenrNDVINotif = this.props.firebase.db.ref(
        "FBfunctionJobs/RemoteSensingNotifications"
      );
      this.listenrNDVINotif.on("value", (result) => {
        this.setState({
          numNDVINotifs: result.val() ? Object.keys(result.val()).length : 0,
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.notificationsListener)
      for (let key in this.notificationsListener)
        this.notificationsListener[key].off();

    if (this.chatListener)
      for (let key in this.chatListener) this.chatListener[key].off();

    if (this.listenrCloudNotif) this.listenrCloudNotif.off();

    if (this.listenrNDVINotif) this.listenrNDVINotif.off();
  }

  onNotificationClick = async (notificationID, notification) => {
    //this.handleNotificationDrawerToggle();
    console.log(notification);

    if (notification.type == 1) {
      //this.NDVIModal.current.showTiff(notification.uid, notification.fieldID, notification.scanDate);
      this.NDVIModal.showTiff(
        notification.uid,
        notification.fieldID,
        notification.scanDate
      );
    } else if (notification.type == 2) {
      window.open("https://pro.agrio.app/report/" + notification.rid, "_blank"); //to open new page
    } else if (notification.type == 3) {
      let fieldID = notification.rid.substring(13, 26);
      // console.log(fieldID);
      // console.log(notification.uid);
      this.handleSelectField(notification.uid, fieldID);
    } else if (notification.type == 4) {
      this.handleSelectUserFeedback(notification.uid, notification.imageName);
    } else if (notification.type == 5) {
      this.handleSelectField(notification.owner, notification.fieldID);
    } else if (notification.type == 98) {
      //console.log(notification);
      await this.props.firebase.db
        .ref("openWorkgroupRequests_review")
        .child(notification.mid)
        .once("value", (result) => {
          //console.log(result.val());

          this.requestRef.handleOpen(notification.mid, result.val());
        });
    } else if (notification.type == 99) {
      if (notification.hasOwnProperty("fieldID"))
        this.handleSelectField(notification.uid, notification.fieldID);
      else if (notification.hasOwnProperty("uid"))
        this.handleSelectField(notification.uid, null);

      if (
        notification.hasOwnProperty("link") &&
        notification.link.indexOf("pdf") != -1
      ) {
        const regex1 = /\/([^\/]*)\/([^\/]*)\/([^\/]*)\.pdf$/;
        const match1 = notification.link.match(regex1);

        const str = match1[3]; // "16707096000001670745209763_1670765982266"

        console.log(str);
        let splitted = str.split("%2F");

        const substring1 = splitted[1]; // "90RoDyxlvlUYjwTiToOuOB0qfHj1"
        const substring2 = splitted[2]; // "1670745209763"
        const substring3 = splitted[3]; // "1670745209763"

        this.props.firebase.storage
          .ref(
            "reportPDFs/" +
              substring1 +
              "/" +
              substring2 +
              "/" +
              substring3 +
              ".pdf"
          )
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            window.open(url, "_blank", "noopener,noreferrer");
          })
          .catch((error) => console.log(error));
      }
    }

    console.log("read");
    this.props.firebase
      .notificationsReference(this.props.firebase.workgroupPath)
      .child(notificationID)
      .child("read")
      .set(true);
  };

  onChatRoomClick = () => {};

  onMarkAllNotificationsRead = async () => {
    let keys = Object.keys(this.state.notifications);

    for (let i = 0; i < keys.length; i++) {
      let notificationID = keys[i];

      this.props.firebase
        .notificationsReference(this.props.firebase.workgroupPath)
        .child(notificationID)
        .child("read")
        .set(true);
    }
  };

  openNDVIModal = (uid, fieldID, fieldName) => {
    //console.log(uid + " " + fieldID);
    //TODO replace the "" by scanDate
    this.NDVIModal.showTiff(uid, fieldID, "");
  };

  handleSectionChange = (path) => {
    if (path === this.props.location.pathname) return;

    if (path === "signout") this.props.firebase.doSignOut();
    else {
      this.props.history.push(path);
    }
  };

  handleTabChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  handleSelectField = (uid, fieldID) => {
    this.props.history.push(ROUTES.FIELDS);
    this.setState({ modalSelection: { uid: uid, fieldID: fieldID } });
  };

  handleSelectUserFeedback = (uid, imageName) => {
    this.props.history.push(ROUTES.PHYTOPATHOLOGY);
    this.setState({ selectedTab: 2 });
  };

  handleContactUs = () => {
    this.messagingRef.handleMessagingOpen(
      "Contact us",
      "Have a question? write to us and we will be in touch soon"
    );
  };

  notifyUsers = () => {
    // console.log("notifyUsers");

    var notif = this.props.firebase.functions.httpsCallable(
      "notifUsersRemoteSensing"
    );
    notif({})
      .then((result) => {
        // Read result of the Cloud Function.
        //var sanitizedMessage = result.data.text;
        console.log("success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openWGMenu = () => {
    // console.log("open");
    this.setState({ isWGOpen: true });
  };

  handleChangeTempUnits = (tempUnits) => {
    console.log("here");
    this.setState({ tempUnits: tempUnits });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Navigator
              path={this.props.location.pathname}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              isWGOpen={this.state.isWGOpen}
              openWGMenu={this.openWGMenu}
              handleWGMenuClose={() => {
                this.setState({ isWGOpen: false });
              }}
              handleChildrenClick={this.handleSectionChange}
              unReadNotification={this.state.unReadNotificationOtherWG}
              numUnReadOtherWG={this.state.numUnReadNotificationOtherWG}
              workgroupLogo={this.state.workgroupLogo}
              menuType={
                this.props.firebase.auth.currentUser.uid ===
                "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
                  ? "superAdmin"
                  : this.props.userType === "admin"
                  ? "WGAdmin"
                  : "User"
              }
            />
          </nav>
          <nav className={classes.drawer}>
            <NotificationDrawer
              PaperProps={{ style: { width: notificationDrawerWidth } }}
              notifications={this.state.notifications}
              variant="temporary"
              anchor={"right"}
              handleClick={this.onNotificationClick}
              handleAllRead={this.onMarkAllNotificationsRead}
              open={this.state.notificationDrawerOpen}
              onClose={this.handleNotificationDrawerToggle}
              notifyUsers={this.notifyUsers}
              adminFlag={
                this.props.firebase.auth.currentUser.uid ===
                "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
              }
              numCloudyNotifs={this.state.numCloudyNotifs}
              numNDVINotifs={this.state.numNDVINotifs}
            />
          </nav>
          <nav className={classes.drawer}>
            <ChatDrawer
              PaperProps={{ style: { width: chatDrawerWidth } }}
              chatRooms={this.state.chatRooms}
              variant="temporary"
              anchor={"right"}
              handleClick={this.onChatRoomClick}
              handleAllRead={this.onMarkAllNotificationsRead}
              open={this.state.chatDrawerOpen}
              onClose={this.handleChatDrawerToggle}
              notifyUsers={this.notifyUsers}
              adminFlag={
                this.props.firebase.auth.currentUser.uid ===
                "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
              }
            />
          </nav>
          <nav className={classes.drawer}>
            <WeatherDrawer
              PaperProps={{
                style: {
                  width: weatherDrawerWidth,
                  backgroundColor: "#ffffff",
                },
              }}
              variant="temporary"
              anchor={"right"}
              open={this.state.weatherDrawerOpen}
              fieldID={
                this.state.selectedFieldID ? this.state.selectedFieldID : null
              }
              uid={this.state.selectedUID ? this.state.selectedUID : null}
              tempUnits={this.state.tempUnits}
              onChangeTempUnits={this.handleChangeTempUnits}
              onClose={this.handleWeatherDrawerToggle}
              adminFlag={
                this.props.firebase.auth.currentUser.uid ===
                "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
              }
            />
          </nav>
          <div className={classes.appContent} style={{ zIndex: 300 }}>
            <Header
              path={this.props.location.pathname}
              profile_image_uri={
                this.state.user_profile_image_uri === "Not Found"
                  ? require("../../Assests/user_icon.png")
                  : this.state.user_profile_image_uri
              }
              onTabChange={this.handleTabChange}
              onDrawerToggle={this.handleDrawerToggle}
              selectedTab={this.state.selectedTab}
              onNotifiationDrawerToggle={this.handleNotificationDrawerToggle}
              onOpenChat={this.handleChatDrawerToggle}
              unReadNotification={this.state.unReadNotification}
              numUnReadOtherWG={this.state.numUnReadNotificationOtherWG}
              userType={
                this.props.firebase.auth.currentUser.uid ===
                "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"
                  ? "superAdmin"
                  : this.props.userType
              }
              workgroupPath={this.props.firebase.workgroupPath}
              onContactUs={this.handleContactUs}
            />
            <main className={classes.mainContent}>
              <Switch>
                <div>
                  <Route
                    path={ROUTES.MEMBERS}
                    render={() => (
                      <WorkgroupPage
                        selectedTab={this.state.selectedTab}
                        classes={classes}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.FIELDS}
                    render={() => (
                      <RemoteSensing
                        key="remote"
                        modalSelection={this.state.modalSelection}
                        handleOpenNDVIModal={this.openNDVIModal}
                        handleWeatherDrawerToggle={this.handleOpenWeatherDrawer}
                        classes={classes}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.PHYTOPATHOLOGY}
                    render={() => (
                      <TreatmentPage
                        selectedTab={this.state.selectedTab}
                        classes={classes}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={ROUTES.HOME}
                    render={() => <OnBoarding classes={classes} />}
                  />
                </div>
              </Switch>
            </main>
          </div>
        </div>
        <NDVIModal
          handleSelectField={this.handleSelectField}
          innerRef={(child) => (this.NDVIModal = child)}
        />
        <MessagingDialog
          targetUID={"Z7JhnzVdY4YMqJ5vcT2dTdNHaym1"}
          onRef={(actualChild) => (this.messagingRef = actualChild)}
        />
        <WorkgroupRequestDialog
          onRef={(actualChild) => (this.requestRef = actualChild)}
        />
      </React.Fragment>
    );
  }
}

Base.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  // console.log(userType);
  // console.log(!(!authUser || userType!=="admin"));
  return !(
    !authUser ||
    (workgroupID == null &&
      userType !== "admin" &&
      premiumLevel === null &&
      premiumLevel === "Premium")
  );
};

export default withAuthorization(condition)(
  withFirebase(withStyles(styles)(Base))
);
