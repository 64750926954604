import React, { PureComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";

import Timestamp from "./Timestamp";

import { withFirebase } from "./Firebase";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "40vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "30vh",
    overflowY: "auto",
  },
});

class Chat extends PureComponent {
  constructor() {
    super();

    this.state = {
      nicknames: {},
      message: null,
    };
  }

  async componentDidMount() {
    let tmp = this.props.responses;
    let processing = [];
    let namePromises = [];
    let names = {};
    for (let i = 0; i < tmp.length; i++)
      if (processing.indexOf(tmp[i].answeringUser) == -1) {
        processing.push(tmp[i].answeringUser);
        namePromises.push(
          this.props.firebase
            .user2nickname(
              this.props.firebase.workgroupPath,
              tmp[i].answeringUser
            )
            .then((name) => {
              names[tmp[i].answeringUser] = name;
            })
        );
      }

    Promise.all(namePromises)
      .then(async () => {
        let message = null;
        if (this.props.hasOwnProperty("message")) message = this.props.message;

        this.setState({
          nicknames: names,
          message: message,
        });
      })
      .catch((error) => console.log(error));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.responses.length !== this.props.responses.length ||
      nextProps.message !== this.props.mesage
    ) {
      let tmp = nextProps.responses;
      let processing = [];
      let namePromises = [];
      let names = {};
      for (let i = 0; i < tmp.length; i++)
        if (processing.indexOf(tmp[i].answeringUser) == -1) {
          processing.push(tmp[i].answeringUser);
          namePromises.push(
            nextProps.firebase
              .user2nickname(
                nextProps.firebase.workgroupPath,
                tmp[i].answeringUser
              )
              .then((name) => {
                names[tmp[i].answeringUser] = name;
              })
          );
        }

      Promise.all(namePromises).then(() => {
        this.setState({
          nicknames: names,
          message: nextProps.hasOwnProperty("message")
            ? nextProps.message
            : null,
        });
      });
    }
  }

  handlePoorImages = () => {
    this.setState({
      message:
        "Thank you for reaching out. To provide the most accurate diagnosis, please send clear, sharp images showing both sides of the leaves as well as an overall view of the entire plant.",
    });
  };

  render() {
    const {
      classes,
      myUID,
      answeringUser,
      responses,
      width,
      includeMessaging,
      sendMessage,
    } = this.props;

    // const [nameState , setNameState] = useState(props)
    //
    //  useEffect(() => {
    //      setNameState(props);
    //  }, [props])

    return (
      <div style={{ width: width }}>
        <List className={classes.messageArea}>
          {responses.map((item, index) => (
            <ListItem
              style={{
                justifyContent:
                  item.answeringUser === myUID ? "flex-end" : "flex-start",
              }}
              key={index}
            >
              <Grid style={{ width: width - 100 }} container>
                <Grid
                  style={{ display: "flex", flexDirection: "row" }}
                  item
                  xs={12}
                >
                  {item.answeringUser !== myUID && (
                    <Avatar style={{ margin: 8 }} />
                  )}
                  <ListItemText
                    align={"left"}
                    primary={this.state.nicknames[item.answeringUser]}
                    secondary={item.answerText}
                  ></ListItemText>
                </Grid>
                <Grid item xs={12}>
                  <ListItemText
                    align={item.answeringUser === myUID ? "left" : "right"}
                    secondary={Timestamp(item.dateForSorting)}
                  ></ListItemText>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        {includeMessaging && (
          <React.Fragment>
            <Divider />
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={10}>
                <TextField
                  multiline
                  id="outlined-basic-email"
                  label={
                    answeringUser !== myUID
                      ? this.state.nicknames[answeringUser]
                      : "Type Something"
                  }
                  value={this.state.message}
                  onChange={(event) =>
                    this.setState({ message: event.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid xs={2} align="right">
                <Fab
                  onClick={() => {
                    sendMessage(this.state.message);
                    this.setState({ message: null });
                  }}
                  color="primary"
                  aria-label="add"
                >
                  <SendIcon />
                </Fab>
              </Grid>
            </Grid>
            <Divider />
            <div onClick={this.handlePoorImages}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: "#01496d",
                  cursor: "pointer",
                }}
              >
                Poor images
              </Typography>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withFirebase(Chat));
