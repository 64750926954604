import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as Helper from "../../Helper";

import { withFirebase } from "../Firebase";

class MessagingDialog extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      title: "",
      subtitle: "",
      messagingOpen: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleMessagingOpen = (title, subtitle) => {
    this.setState({
      messagingOpen: true,
      title: title ? title : "Compose your message",
      subtitle: subtitle
        ? subtitle
        : "  Write a message that will be sent directly to the user's app.",
    });
  };

  handleSendMessage = () => {
    var nowDate = new Date();
    var dateForSorting = nowDate * 1;

    let sorted = Helper.sortByValue(
      [this.props.firebase.auth.currentUser.uid, this.props.targetUID],
      null,
      "str"
    );

    let seenObj = {};
    seenObj[this.props.firebase.auth.currentUser.uid] = true;
    let memberObj = {};
    memberObj[this.props.firebase.auth.currentUser.uid] = dateForSorting;
    memberObj[this.props.targetUID] = dateForSorting;

    let response = {};

    response[dateForSorting + this.props.firebase.auth.currentUser.uid] = {
      answeringUser: this.props.firebase.auth.currentUser.uid,
      answerText: this.state.message,
      addedImageName: "",
      dateForSorting: dateForSorting,
      deleted: false,
    };

    let writeObj = {
      dateForSorting: dateForSorting,
      seen: seenObj,
      memberObj: memberObj,
      responses: response,
    };

    let chatRef = "/Chats/" + "OneOnOne" + "/" + sorted[0] + "/" + sorted[1];

    console.log(this.props.firebase.workgroupPath + chatRef);
    console.log(writeObj);

    this.props.firebase._updateDatabase(
      this.props.firebase.workgroupPath + chatRef,
      writeObj,
      1000
    );

    // console.log(dbPath);
    // console.log(sharingObj);

    this.setState({ messagingOpen: false, message: "" });
  };

  handleMessagingClose = () => {
    this.setState({ messagingOpen: false });
  };

  handleMessageChange = (event) => {
    //console.log(event.target.value);
    this.setState({ message: event.target.value });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.messagingOpen}
          onClose={this.handleMessagingClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.subtitle}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="message"
              label="Your message"
              type="text"
              fullWidth
              multiline
              onChange={this.handleMessageChange}
              value={this.state.message}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleMessagingClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSendMessage} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withFirebase(MessagingDialog);
